// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-allergen-nutritional-data-search-jsx": () => import("./../../../src/pages/allergen-nutritional-data-search.jsx" /* webpackChunkName: "component---src-pages-allergen-nutritional-data-search-jsx" */),
  "component---src-pages-api-jsx": () => import("./../../../src/pages/api.jsx" /* webpackChunkName: "component---src-pages-api-jsx" */),
  "component---src-pages-api-policy-jsx": () => import("./../../../src/pages/api-policy.jsx" /* webpackChunkName: "component---src-pages-api-policy-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-caterers-jsx": () => import("./../../../src/pages/caterers.jsx" /* webpackChunkName: "component---src-pages-caterers-jsx" */),
  "component---src-pages-contact-us-index-jsx": () => import("./../../../src/pages/contact-us/index.jsx" /* webpackChunkName: "component---src-pages-contact-us-index-jsx" */),
  "component---src-pages-contact-us-success-jsx": () => import("./../../../src/pages/contact-us/success.jsx" /* webpackChunkName: "component---src-pages-contact-us-success-jsx" */),
  "component---src-pages-cookies-jsx": () => import("./../../../src/pages/cookies.jsx" /* webpackChunkName: "component---src-pages-cookies-jsx" */),
  "component---src-pages-demo-success-jsx": () => import("./../../../src/pages/demo/success.jsx" /* webpackChunkName: "component---src-pages-demo-success-jsx" */),
  "component---src-pages-erp-contact-jsx": () => import("./../../../src/pages/erp/contact.jsx" /* webpackChunkName: "component---src-pages-erp-contact-jsx" */),
  "component---src-pages-erp-index-jsx": () => import("./../../../src/pages/erp/index.jsx" /* webpackChunkName: "component---src-pages-erp-index-jsx" */),
  "component---src-pages-erp-success-jsx": () => import("./../../../src/pages/erp/success.jsx" /* webpackChunkName: "component---src-pages-erp-success-jsx" */),
  "component---src-pages-fadne-contact-us-jsx": () => import("./../../../src/pages/fadne/contact-us.jsx" /* webpackChunkName: "component---src-pages-fadne-contact-us-jsx" */),
  "component---src-pages-fadne-index-jsx": () => import("./../../../src/pages/fadne/index.jsx" /* webpackChunkName: "component---src-pages-fadne-index-jsx" */),
  "component---src-pages-food-allergens-jsx": () => import("./../../../src/pages/food-allergens.jsx" /* webpackChunkName: "component---src-pages-food-allergens-jsx" */),
  "component---src-pages-fsa-alerts-jsx": () => import("./../../../src/pages/fsa-alerts.jsx" /* webpackChunkName: "component---src-pages-fsa-alerts-jsx" */),
  "component---src-pages-image-capture-bookings-jsx": () => import("./../../../src/pages/image-capture/bookings.jsx" /* webpackChunkName: "component---src-pages-image-capture-bookings-jsx" */),
  "component---src-pages-image-capture-index-jsx": () => import("./../../../src/pages/image-capture/index.jsx" /* webpackChunkName: "component---src-pages-image-capture-index-jsx" */),
  "component---src-pages-image-capture-success-jsx": () => import("./../../../src/pages/image-capture/success.jsx" /* webpackChunkName: "component---src-pages-image-capture-success-jsx" */),
  "component---src-pages-image-hub-jsx": () => import("./../../../src/pages/image-hub.jsx" /* webpackChunkName: "component---src-pages-image-hub-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-industry-consultants-jsx": () => import("./../../../src/pages/industry-consultants.jsx" /* webpackChunkName: "component---src-pages-industry-consultants-jsx" */),
  "component---src-pages-industry-resources-allergy-icons-jsx": () => import("./../../../src/pages/industry-resources/allergy-icons.jsx" /* webpackChunkName: "component---src-pages-industry-resources-allergy-icons-jsx" */),
  "component---src-pages-industry-resources-erudus-logos-badges-jsx": () => import("./../../../src/pages/industry-resources/erudus-logos-badges.jsx" /* webpackChunkName: "component---src-pages-industry-resources-erudus-logos-badges-jsx" */),
  "component---src-pages-industry-resources-posters-and-flyers-jsx": () => import("./../../../src/pages/industry-resources/posters-and-flyers.jsx" /* webpackChunkName: "component---src-pages-industry-resources-posters-and-flyers-jsx" */),
  "component---src-pages-industry-resources-uk-food-guidelines-jsx": () => import("./../../../src/pages/industry-resources/uk-food-guidelines.jsx" /* webpackChunkName: "component---src-pages-industry-resources-uk-food-guidelines-jsx" */),
  "component---src-pages-industry-resources-video-tutorials-jsx": () => import("./../../../src/pages/industry-resources/video-tutorials.jsx" /* webpackChunkName: "component---src-pages-industry-resources-video-tutorials-jsx" */),
  "component---src-pages-integration-partners-jsx": () => import("./../../../src/pages/integration-partners.jsx" /* webpackChunkName: "component---src-pages-integration-partners-jsx" */),
  "component---src-pages-know-your-food-demo-jsx": () => import("./../../../src/pages/know-your-food/demo.jsx" /* webpackChunkName: "component---src-pages-know-your-food-demo-jsx" */),
  "component---src-pages-know-your-food-index-jsx": () => import("./../../../src/pages/know-your-food/index.jsx" /* webpackChunkName: "component---src-pages-know-your-food-index-jsx" */),
  "component---src-pages-manufacturers-demo-jsx": () => import("./../../../src/pages/manufacturers/demo.jsx" /* webpackChunkName: "component---src-pages-manufacturers-demo-jsx" */),
  "component---src-pages-manufacturers-jsx": () => import("./../../../src/pages/manufacturers.jsx" /* webpackChunkName: "component---src-pages-manufacturers-jsx" */),
  "component---src-pages-manufacturers-sign-up-jsx": () => import("./../../../src/pages/manufacturers/sign-up.jsx" /* webpackChunkName: "component---src-pages-manufacturers-sign-up-jsx" */),
  "component---src-pages-menu-planner-jsx": () => import("./../../../src/pages/menu-planner.jsx" /* webpackChunkName: "component---src-pages-menu-planner-jsx" */),
  "component---src-pages-newsletter-success-jsx": () => import("./../../../src/pages/newsletter-success.jsx" /* webpackChunkName: "component---src-pages-newsletter-success-jsx" */),
  "component---src-pages-open-days-sign-up-jsx": () => import("./../../../src/pages/open-days/sign-up.jsx" /* webpackChunkName: "component---src-pages-open-days-sign-up-jsx" */),
  "component---src-pages-open-days-success-jsx": () => import("./../../../src/pages/open-days/success.jsx" /* webpackChunkName: "component---src-pages-open-days-success-jsx" */),
  "component---src-pages-pim-contact-jsx": () => import("./../../../src/pages/pim/contact.jsx" /* webpackChunkName: "component---src-pages-pim-contact-jsx" */),
  "component---src-pages-pim-index-jsx": () => import("./../../../src/pages/pim/index.jsx" /* webpackChunkName: "component---src-pages-pim-index-jsx" */),
  "component---src-pages-pim-success-jsx": () => import("./../../../src/pages/pim/success.jsx" /* webpackChunkName: "component---src-pages-pim-success-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-recipe-builder-jsx": () => import("./../../../src/pages/recipe-builder.jsx" /* webpackChunkName: "component---src-pages-recipe-builder-jsx" */),
  "component---src-pages-retailers-jsx": () => import("./../../../src/pages/retailers.jsx" /* webpackChunkName: "component---src-pages-retailers-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-sign-up-success-jsx": () => import("./../../../src/pages/sign-up/success.jsx" /* webpackChunkName: "component---src-pages-sign-up-success-jsx" */),
  "component---src-pages-technical-data-search-jsx": () => import("./../../../src/pages/technical-data-search.jsx" /* webpackChunkName: "component---src-pages-technical-data-search-jsx" */),
  "component---src-pages-terms-conditions-jsx": () => import("./../../../src/pages/terms-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-conditions-jsx" */),
  "component---src-pages-wholesalers-demo-jsx": () => import("./../../../src/pages/wholesalers/demo.jsx" /* webpackChunkName: "component---src-pages-wholesalers-demo-jsx" */),
  "component---src-pages-wholesalers-jsx": () => import("./../../../src/pages/wholesalers.jsx" /* webpackChunkName: "component---src-pages-wholesalers-jsx" */),
  "component---src-pages-whos-using-erudus-jsx": () => import("./../../../src/pages/whos-using-erudus.jsx" /* webpackChunkName: "component---src-pages-whos-using-erudus-jsx" */),
  "component---src-templates-careers-job-post-jsx": () => import("./../../../src/templates/careers/JobPost.jsx" /* webpackChunkName: "component---src-templates-careers-job-post-jsx" */),
  "component---src-templates-editorial-article-jsx": () => import("./../../../src/templates/editorial/Article.jsx" /* webpackChunkName: "component---src-templates-editorial-article-jsx" */),
  "component---src-templates-editorial-hidden-article-jsx": () => import("./../../../src/templates/editorial/HiddenArticle.jsx" /* webpackChunkName: "component---src-templates-editorial-hidden-article-jsx" */),
  "component---src-templates-editorial-insider-diary-jsx": () => import("./../../../src/templates/editorial/InsiderDiary.jsx" /* webpackChunkName: "component---src-templates-editorial-insider-diary-jsx" */),
  "component---src-templates-editorial-list-jsx": () => import("./../../../src/templates/editorial/List.jsx" /* webpackChunkName: "component---src-templates-editorial-list-jsx" */),
  "component---src-templates-editorial-recipe-book-jsx": () => import("./../../../src/templates/editorial/RecipeBook.jsx" /* webpackChunkName: "component---src-templates-editorial-recipe-book-jsx" */),
  "component---src-templates-events-event-jsx": () => import("./../../../src/templates/events/Event.jsx" /* webpackChunkName: "component---src-templates-events-event-jsx" */),
  "component---src-templates-events-list-jsx": () => import("./../../../src/templates/events/List.jsx" /* webpackChunkName: "component---src-templates-events-list-jsx" */),
  "component---src-templates-news-article-jsx": () => import("./../../../src/templates/news/Article.jsx" /* webpackChunkName: "component---src-templates-news-article-jsx" */),
  "component---src-templates-news-datapool-jsx": () => import("./../../../src/templates/news/Datapool.jsx" /* webpackChunkName: "component---src-templates-news-datapool-jsx" */),
  "component---src-templates-news-list-jsx": () => import("./../../../src/templates/news/List.jsx" /* webpackChunkName: "component---src-templates-news-list-jsx" */),
  "component---src-templates-news-podcast-jsx": () => import("./../../../src/templates/news/Podcast.jsx" /* webpackChunkName: "component---src-templates-news-podcast-jsx" */),
  "component---src-templates-sign-up-jsx": () => import("./../../../src/templates/SignUp.jsx" /* webpackChunkName: "component---src-templates-sign-up-jsx" */),
  "component---src-templates-welcome-page-jsx": () => import("./../../../src/templates/WelcomePage.jsx" /* webpackChunkName: "component---src-templates-welcome-page-jsx" */)
}

