export const global = [
  {
    name: 'Manufacturers',
    to: '/manufacturers',
  },
  {
    name: 'Wholesalers',
    to: '/wholesalers',
  },
  {
    name: 'Caterers',
    to: '/caterers',
  },
  {
    name: 'Retailers',
    to: '/retailers',
  },
]

export const primary = [
  {
    name: 'About',
    to: '/about',
  },
  {
    name: 'Food Allergens',
    to: '/food-allergens',
  },
  {
    name: 'News',
    to: '/news',
  },
  {
    name: 'Editorial',
    to: '/editorial',
  },
  {
    name: 'Events',
    to: '/events',
  },
  {
    name: 'Industry Resources',
    to: '/industry-resources/allergy-icons',
  },
  {
    name: 'Contact us',
    to: '/contact-us',
  },
]

export const solutions = [
  {
    name: 'Technical Data Search',
    to: '/technical-data-search',
  },
  {
    name: 'Allergen & Nutritional Data Search',
    to: '/allergen-nutritional-data-search',
  },
  {
    name: 'Recipe Builder',
    to: '/recipe-builder',
  },
  {
    name: 'Menu Planner',
    to: '/menu-planner',
  },
  {
    name: 'Image Hub',
    to: '/image-hub',
  },
  {
    name: 'Image Capture',
    to: '/image-capture',
  },
  {
    name: 'APIs',
    to: '/api',
  },
  {
    name: 'Connector for Akeneo PIM',
    to: '/pim',
  },
]

export const support = [
  {
    name: 'Careers',
    to: '/careers',
  },
  {
    name: 'FSA Alerts',
    to: '/fsa-alerts',
  },
  {
    name: 'Industry Consultants',
    to: '/industry-consultants',
  },
  {
    name: 'Integration Partners',
    to: '/integration-partners',
  },
]

export const news = [
  {
    name: 'Erudus News',
    to: '/news/erudus',
  },
  {
    name: 'Industry News',
    to: '/news/industry',
  },
  {
    name: 'Data Pool updates',
    to: '/news/data-pool',
  },
  {
    name: 'Podcast',
    to: '/news/podcast',
  },
  {
    name: 'Release Notes',
    to: '/news/release-notes',
  },
  {
    name: 'You should know...',
    to: '/news/you-should-know',
  },
]

export const editorial = [
  {
    name: 'The Food Agenda',
    to: '/editorial/the-food-agenda',
  },
  {
    name: 'Recipe Book',
    to: '/editorial/recipe-book',
  },
  {
    name: 'Insider Diaries',
    to: '/editorial/insider-diaries',
  },
  {
    name: 'Erudus Community',
    to: '/editorial/erudus-community',
  },
  {
    name: 'The Takeaway',
    to: '/editorial/the-takeaway',
  },
]

export const industryResources = [
  {
    name: 'Allergy Icons',
    to: '/industry-resources/allergy-icons',
  },
  {
    name: 'Video Tutorials',
    to: '/industry-resources/video-tutorials',
  },
  {
    name: 'Allergen Posters & Flyers',
    to: '/industry-resources/posters-and-flyers',
  },
  {
    name: 'Erudus Logo’s & Badges',
    to: '/industry-resources/erudus-logos-badges',
  },
  {
    name: 'UK Food Guidelines',
    to: '/industry-resources/uk-food-guidelines',
  },
]
