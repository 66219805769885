import React from 'react'
import { Link } from 'gatsby'
import cn from 'classnames'
import Container from './elements/Container'
import styles from '../scss/components/footer.module.scss'

import { solutions, support, news, editorial } from '../../config/navigation'

import LogoSVG from './../assets/svg/footer-logo.svg'
import PhoneSVG from './../assets/svg/phone.svg'
import EmailSVG from './../assets/svg/email-edit.svg'

const Footer = () => (
  <footer data-datocms-noindex>
    <div className={styles.linksFooterWrapper}>
      <div className="container">
        <div className="row pt-3 pb-5">
          <div className={styles.logoLg}>
            <LogoSVG />
          </div>
        </div>

        <div className={styles.linksFooterGrid}>
          <nav>
            <h6 className="mb-4">Solutions</h6>
            <ul className={styles.linksFooterList}>
              {solutions.map((link) => (
                <li key={link.name} className={styles.linksFooterListItem}>
                  <Link to={link.to} title={link.name}>
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>

          <nav>
            <h6 className="mb-4">Further Support</h6>
            <ul className={styles.linksFooterList}>
              {support.map((link) => (
                <li key={link.name} className={styles.linksFooterListItem}>
                  <Link to={link.to} title={link.name}>
                    {link.name}
                  </Link>
                </li>
              ))}
              <li className={styles.linksFooterListItem}>
                <a
                  href="https://status.erudus.com/"
                  title="System Status"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  System Status
                </a>
              </li>
            </ul>
          </nav>

          <nav>
            <h6 className="mb-4">News</h6>
            <ul className={styles.linksFooterList}>
              {news.map((link) => (
                <li key={link.name} className={styles.linksFooterListItem}>
                  <Link to={link.to} title={link.name}>
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>

          <nav>
            <h6 className="mb-4">Editorial</h6>
            <ul className={styles.linksFooterList}>
              {editorial.map((link) => (
                <li key={link.name} className={styles.linksFooterListItem}>
                  <Link to={link.to} title={link.name}>
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </div>

    <div className={styles.infoFooterWrapper}>
      <Container large>
        <div className="row pb-3 d-lg-none">
          <div className={styles.logo}>
            <LogoSVG />
          </div>
        </div>

        <div className={styles.contact}>
          <ul className={styles.infoList}>
            <li className={styles.infoListItem}>
              <PhoneSVG className={styles.iconPhone} />
              <a href="tel:03331218999">0333 121 89 99</a>
            </li>
            <li className={styles.infoListItem}>
              <EmailSVG className={styles.iconEmail} />
              <a href="mailto:support@erudus.com">support@erudus.com</a>
            </li>
            <li className={cn(styles.infoListItem, styles.hours)}>
              Monday - Friday, 09:00 - 17:00
            </li>
          </ul>
          <p className={styles.strapline}>United around food data.</p>
        </div>

        <div className={styles.copy}>
          <p className={styles.copyText}>
            © {new Date().getFullYear()} Erudus Limited
            <span className="d-none d-sm-none d-md-inline">&nbsp;•&nbsp;</span>
            <br className="d-xs-none d-sm-none" />
            Company no. 06315071 &nbsp;•&nbsp; VAT no. 917332138
            <br />
            Erudus Limited Panther House, Asama Court, Newcastle Business Park,
            Newcastle Upon Tyne, NE4 7YD, United Kingdom
          </p>
          <ul className={styles.copyLinks}>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li className={styles.divider}>|</li>
            <li>
              <Link to="/terms-conditions">Terms & Conditions</Link>
            </li>
            <li className={styles.divider}>|</li>
            <li>
              <Link to="/cookies">Cookies</Link>
            </li>
          </ul>
        </div>
      </Container>
    </div>
  </footer>
)

export default Footer
