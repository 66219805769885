import React, { useState } from 'react'
import { Link } from 'gatsby'

import { global, primary, solutions, support } from '../../config/navigation'

import LogoSVG from './../assets/svg/logo.svg'
import LoginSVG from './../assets/svg/login.svg'

const Header = ({ path }) => {
  const [navIsOpen, setNavIsOpen] = useState(false)

  let buttonClass = 'c-menuButton'
  let className = 'c-gNavbarMobile'

  if (navIsOpen) {
    buttonClass += ' is-active'
    className += ' is-active'
  }

  let menuText = navIsOpen ? `Close Menu` : `Menu`

  const setPartiallyActive = (currentPath, linkPath) => {
    let partiallyActive =
      currentPath.startsWith('/industry-resources') &&
      linkPath.startsWith('/industry-resources')

    if (partiallyActive) {
      return `is-active`
    }
  }

  return (
    <header data-datocms-noindex>
      <nav id="global-navbar" className="c-gNavbar">
        <div className="c-gNavbar__inner l-container--large">
          <div className={buttonClass} onClick={() => setNavIsOpen(!navIsOpen)}>
            <div className="c-hamburger">
              <div className="c-hamburger__box">
                <div className="c-hamburger__inner"></div>
              </div>
            </div>
            <span className="c-menuButton__text">{menuText}</span>
          </div>

          <div className="c-gNavbar__menu">
            <ul className={className}>
              <li className="c-gNavbarMobile__home">
                <Link to="/" title="Home">
                  Home
                </Link>
              </li>

              {primary.map((link) => (
                <li key={`nav-primary-${link.name}`}>
                  <Link
                    to={link.to}
                    title={link.name}
                    activeClassName="is-active"
                    partiallyActive={true}
                    onClick={() => setNavIsOpen(false)}
                    className={setPartiallyActive(path, link.to)}
                  >
                    {link.name}
                  </Link>
                </li>
              ))}

              <li className="c-gNavbarMobile__footerHeader">
                <h6>Solutions</h6>
              </li>

              {solutions.map((link) => (
                <li
                  key={`nav-support-${link.name}`}
                  className="c-gNavbarMobile__footerLink"
                >
                  <Link
                    to={link.to}
                    title={link.name}
                    onClick={() => setNavIsOpen(false)}
                  >
                    {link.name}
                  </Link>
                </li>
              ))}

              <li className="c-gNavbarMobile__footerHeader">
                <h6>Further Support</h6>
              </li>

              {support.map((link) => (
                <li
                  key={`nav-support-${link.name}`}
                  className="c-gNavbarMobile__footerLink"
                >
                  <Link
                    to={link.to}
                    title={link.name}
                    onClick={() => setNavIsOpen(false)}
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
              <li className="c-gNavbarMobile__footerLink">
                <a
                  href="https://status.erudus.com/"
                  title="System Status"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  System Status
                </a>
              </li>
            </ul>
            <ul className="c-gNavbarLogin">
              <li>
                <a
                  href="https://app.erudus.com/"
                  title="Click here to log into Erudus"
                >
                  Login
                  <LoginSVG className="c-icon ml-2" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <nav id="primary-navbar" className="c-pNavbar">
        <div className="c-pNavbar__container l-container--large">
          <div className="c-logo">
            <Link to="/">
              <span style={{ display: 'block', transform: 'scale(1.1)' }}>
                <LogoSVG alt="Logo" />
              </span>
            </Link>
          </div>
          <ul>
            {global.map((link) => (
              <li
                key={`nav-global-${link.name}`}
                className={`c-pNavbar--${link.name.toLowerCase()} ${
                  path === link.to ? 'is-active' : ''
                }`}
              >
                <Link to={link.to} title={`For ${link.name}`}>
                  For <strong>{link.name}</strong>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </header>
  )
}

export default Header
