import React from 'react'
import cn from 'classnames'

export default function Container({
  children,
  large = false,
  className = '',
  ...props
}) {
  const cClass = large ? 'l-container--large' : 'container'

  return (
    <div className={cn(cClass, className)} {...props}>
      {children}
    </div>
  )
}
