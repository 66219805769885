import React, { Fragment, useContext } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { SkipNavLink } from '@reach/skip-nav'
import CookieConsent from 'react-cookie-consent'

import Header from '../components/Header'
import Footer from '../components/Footer'

import ImageModalContext from '../store/ImageModalContext'
import ImageModal from '../modals/ImageModal'

import '@reach/skip-nav/styles.css'
import '../assets/scss/main.scss'

const Layout = ({ children, path }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      datoCmsSite {
        faviconMetaTags {
          ...GatsbyDatoCmsFaviconMetaTags
        }
      }
    }
  `)

  const { image } = useContext(ImageModalContext)

  function renderModal() {
    if (!image) return null
    return <ImageModal />
  }

  return (
    <Fragment>
      <HelmetDatoCms favicon={data.datoCmsSite.faviconMetaTags} />
      <SkipNavLink data-datocms-noindex />
      <Header path={path} />
      {children}
      {renderModal()}
      <Footer />

      <CookieConsent
        disableStyles={true}
        containerClasses="c-cookieConsent"
        contentClasses="c-cookieConsent__content"
        buttonClasses="c-cookieConsent__button"
      >
        This website or its third-party tools use cookies, which are necessary
        to its functioning and required to achieve the purposes illustrated in
        the cookie policy. If you want to know more or withdraw your consent to
        all or some of the cookies, please refer to the{' '}
        <Link to="/cookies" className="c-link">
          cookie policy
        </Link>
        . By continuing to browse you agree to the use of cookies.
      </CookieConsent>
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
