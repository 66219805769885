import React, { useState } from 'react'
import PropTypes from 'prop-types'

const defaultState = {
  image: null,
}

const ImageModalContext = React.createContext(defaultState)

const ImageModalProvider = ({ children }) => {
  const [image, setImage] = useState(defaultState.image)
  const closeImage = () => setImage(null)
  const openImage = (i) => {
    setImage(i)
  }

  return (
    <ImageModalContext.Provider
      value={{
        image,
        openImage,
        closeImage,
      }}
    >
      {children}
    </ImageModalContext.Provider>
  )
}

ImageModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ImageModalContext

export { ImageModalProvider }
