import React, { useContext, useEffect } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import Img from 'gatsby-image'

import ImageModalContext from '../store/ImageModalContext'
import useWindowSize from '../hooks/useWindowSize'

export default function ImageModal() {
  const { image, closeImage } = useContext(ImageModalContext)
  const size = useWindowSize()

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      // Close modal on esc keydown
      if (e.key === 'Escape') {
        closeImage()
      }
    })

    // Remove event listener on cleanup
    return () => document.removeEventListener('keydown', null)
  }, [image]) // Array ensures that effect is only run on state change

  function renderImage() {
    if (!image) return null

    let padding = size.width > 767 ? 120 : 40

    let imageWidth = size.width - padding

    if (imageWidth > image.fluid.presentationWidth) {
      imageWidth = image.fluid.presentationWidth
    }

    return (
      <Img
        fluid={image.fluid}
        loading="eager"
        style={{
          flexGrow: 1,
          maxWidth: '1200px',
          width: `${imageWidth}px`,
        }}
        imgStyle={{
          objectFit: 'contain',
        }}
        className="c-modal__image img-fluid"
      />
    )
  }

  return (
    <div
      className="c-modalOverlay"
      role="dialog"
      aria-labelledby="ImageModal"
      aria-hidden="true"
    >
      <OutsideClickHandler onOutsideClick={closeImage}>
        <div className="c-modalWrapper">
          <button onClick={closeImage} type="button" className="c-modalClose">
            &times;
          </button>

          <div className="c-modal p-2">{renderImage()}</div>
        </div>
      </OutsideClickHandler>
    </div>
  )
}
